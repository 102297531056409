import React from "react";
import packageJson from "../../../../package.json";
import classes from "./Footer.module.css";

const Footer = (props) => {
  let footer = (
    <div
      className={[classes.Footer, classes.Information, classes.Center].join(
        " "
      )}
    >
      <div style={{ width: "100%" }}>
        <span style={{ paddingRight: "0.25rem" }}>Apron Strings</span>|
        <span style={{ paddingLeft: "0.25rem" }}>
          {new Date().getFullYear()}
        </span>
      </div>
      <div style={{ width: "100%" }}>
        <small>{packageJson.version}</small>
      </div>
    </div>
  );
  if (window.screen.width > 992) {
    footer = (
      <React.Fragment>
        <div className={[classes.Footer, classes.Information].join(" ")}>
          <div className={classes.Center}>
            <span style={{ paddingRight: "0.25rem" }}>Apron Strings</span>|
            <span style={{ paddingLeft: "0.25rem" }}>
              {new Date().getFullYear()}
            </span>
            <br />
            <small>{packageJson.version}</small>
          </div>
        </div>
      </React.Fragment>
    );
  }
  return footer;
};

export default Footer;
